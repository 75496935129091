import { useState } from "react";


function TableRow(props) {



    return(
        <tr key={props.rowData.runnerId} onClick={ () => {props.onRowClick(props.rowData.runnerId)}}>
            <td>{props.rowData.firstName}</td>
            <td>{props.rowData.lastName}</td>
            {/* <td>{props.rowData.event}</td> */}
            <td>{props.rowData.ageGroup}</td>
            {/* <td className="alignCenter">
                { props.rowData.bibNumber}  
            </td> */}
            <td className="alignCenter">
                { props.rowData.raceNumber}  
            </td>
        </tr>
    )   
}

export default TableRow;
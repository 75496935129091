import { useState } from "react";
import {Link, useNavigate} from 'react-router-dom'
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'

const LoginUser = () => {

    require('../assets/admin.css')

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const navigate = useNavigate()

    const login = async () => {
        try {
            await signInWithEmailAndPassword(getAuth(), email, password)
            navigate('/admin')
        } catch(e) {
            setError(e.message)
        }
    }

    return (
        <>
        <div className="loginArea adminArea">
            <h1>Login</h1>
            <div class="loginPara">
            <p>Please enter the username and password provided to you by the Nobbler team.</p>
            </div>
            {error && <h2>{error}</h2>}
            <input 
                placeholder="Your email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <input 
            className="passwordField"
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <button className="submitButton" onClick={login}>Log in</button>
        </div>
        </>

    )
}

export default LoginUser;
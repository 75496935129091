import { useState } from "react";
import moment from 'moment';

function TableRowMia(props) {

    const firstdate = props.rowData.firstRead;
    const formattedFirstDate = firstdate && moment(firstdate).isValid() ? moment(firstdate).format('HH:mm:ss') : '';

    const lastdate = props.rowData.lastRead;
    const formattedLastDate = lastdate && moment(lastdate).isValid() ? moment(lastdate).format('HH:mm:ss') : '';

    return(
        <tr key={props.rowData.runnerId} onClick={ () => {props.onRowClick(props.rowData.runnerId)}}>
            <td>{props.rowData.firstName}</td>
            <td>{props.rowData.lastName}</td>
            <td>{props.rowData.mobileNumber}</td>
            <td>{formattedFirstDate}</td>
            <td>{formattedLastDate}</td>
            <td className="alignCenter">
                { props.rowData.raceNumber}  
            </td>
        </tr>
    )   
}

export default TableRowMia;
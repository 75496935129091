import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const useUser = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setIsLoading(false);
        });

        // Cleanup the subscription when the component unmounts
        return () => unsubscribe();
    }, []);  // Empty dependency array to run this effect only once on mount

    return { user, isLoading };  // Return an object with user and isLoading properties
};

export default useUser;
import React from 'react';
import { useState, useEffect } from "react";
import UseUser from "../components/useUser"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {Link} from 'react-router-dom'

import exampleCSV from '../assets/example.csv'

const firebaseConfig = {
    apiKey: "AIzaSyBquTZItciS7iCeSlAc1n2QDtf65E6uPJk",
    authDomain: "dirty-dozen-auth.firebaseapp.com",
    projectId: "dirty-dozen-auth",
    storageBucket: "dirty-dozen-auth.appspot.com",
    messagingSenderId: "790690878675",
    appId: "1:790690878675:web:3f9ac9009ac6e13738f026"
  };

firebase.initializeApp(firebaseConfig);




function UploadRecords() {

    require('../assets/admin.css')

    const {user, isLoading} = UseUser()

    const [file, setFile] = useState(null);
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
    };
  
    const uploadRecords = async (event) => {
      event.preventDefault();
  
      if (!file) {
        alert("Please select a file.");
        return;
      }
  
      const formData = new FormData();
      formData.append('file', file);

      const token = await user.getIdToken()
  
      try {
        const response = await fetch('/upload', {
          method: 'POST',
          headers: { 'authtoken': token },
          body: formData
        });
  
        const result = await response.text();
        alert(result);
      } catch (error) {
        console.error("Error uploading the file:", error);
        alert("Error uploading the file. Please try again.");
      }
    };
  
    return (
      <>
      <div className="loginArea adminArea">
        <h1>Import</h1>
        <Link className="loginLink" to="/admin">Admin</Link>
        <div class="loginPara">
            <p>Upload a CSV. <a href={exampleCSV}>Example CSV here</a>.<br /> All data is overwritten during upload.</p>
            </div>
        {user ? (
            <>
          <form id="uploadForm" onSubmit={uploadRecords} enctype="multipart/form-data">
            <input className="uploadFile" type="file" name="file" accept=".csv" onChange={handleFileChange} required />
            <button className="uploadButton" type="submit">Upload</button>
          </form>
          
          </>
        ) : (
            <Link className="loginLink" to="/login">Login</Link>
        )}  
        </div>
      </>
    );
  }
  
  export default UploadRecords;
import { useState, useEffect } from "react";


function HeaderBlock(props) {

    return (
        <header>
            <img src={props.logo} alt={props.header} className="logo"/>
            <h1>{props.header}</h1>
        </header>
    );
}

export default HeaderBlock;
import { useState, useEffect } from "react";
import HeaderBlock from './regheader'
import {Link} from 'react-router-dom'
import logo from '../assets/nn23.png'
import TableRow from "./tablerow";
import EditingRow from "./editingRow";
import Modal from 'react-modal';
import useUser from "../components/useUser"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBquTZItciS7iCeSlAc1n2QDtf65E6uPJk",
    authDomain: "dirty-dozen-auth.firebaseapp.com",
    projectId: "dirty-dozen-auth",
    storageBucket: "dirty-dozen-auth.appspot.com",
    messagingSenderId: "790690878675",
    appId: "1:790690878675:web:3f9ac9009ac6e13738f026"
  };

firebase.initializeApp(firebaseConfig);
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(1, 1, 1, 0.75)'
    },
    content: {
      top: '245px',
      left: '50%',
      width: '80%',
      maxWidth: '410px',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '10px 20px 5px 20px',
      backgroundColor: "#111111",
      borderColor: "#f15f22"
    },
  };
  


Modal.setAppElement('#root');

// const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
//     if (user) {
//     // The user is signed in. You can now run your code that depends on user authentication.
//     console.log('User is signed in:', user.uid);
//     //FetchData()
//     proceed = true
//     // Don't forget to unsubscribe from the observer when it's no longer needed.
//     unsubscribe();
   
//     } else {
//     // The user is signed out or hasn't signed in yet.
//     // You can handle this case as needed.
//     console.log('User is signed out or has');
//     }
// });

const ResultsTable = () => {

    const {user, isLoading} = useUser()
    const [registeredPeople, setregisteredPeople] = useState(0);
    const [registeredTWT, setegisteredTWT] = useState(0);
    const [registeredBB, setegisteredBB] = useState(0);
    const [registeredPB, setegisteredPB] = useState(0);
    
    const FetchData =  async () => {
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                try {
                    const token = await user.getIdToken()
                    const requestOptions = {
                        method: 'GET',
                        headers: {'Content-Type': 'application/json', 'authtoken': token },
                        // headers: { 'Content-Type': 'application/json' },
                    };
                    
                    fetch('/apiadmin', requestOptions)
                        .then(response => response.json())
                        .then(response => processResults(response))
                        .catch(error => {
                            //setformError(error)
                            //this.setState({ errorMessage: error.toString() });
                            console.error(error);
                            
                        });
                } catch (error){

                    console.log(error)
                }
            }
            else {
              console.log("No user logged in")
            }
          })
      };

  

  // Set up an observer to watch for changes in the user's sign-in state

  
  useEffect(() => {
    FetchData(); 
    const interval = setInterval(() => {
        FetchData(); 
      }, 5000); 

      return () => clearInterval(interval); 
  }, []);


      const processResults = (passedResults) => {
            let registeredPeople = 0
            let registeredTWT = 0
            let registeredBB = 0
            let registeredPB = 0
            const data = passedResults.resultData
            // console.log(passedResults.resultData)
            for (const property in passedResults.resultData) {

                if (passedResults.resultData[property]['raceNumber'] != null && passedResults.resultData[property]['raceNumber'] != "") {
                    if (passedResults.resultData[property]['event'] === "TWT") {
                        registeredTWT ++
                    }
                    if (passedResults.resultData[property]['event'] === "BB") {
                        registeredBB ++
                    }
                    if (passedResults.resultData[property]['event'] === "PB") {
                        registeredPB ++
                    }
                    registeredPeople ++
                };
              }
            setregisteredPeople(registeredPeople)
            setegisteredTWT(registeredTWT)
            setegisteredBB(registeredBB)
            setegisteredPB(registeredPB)
            const raceData = data.slice().sort((a, b) => {
            const lastNameA = a.lastName.toLowerCase();
            const lastNameB = b.lastName.toLowerCase();
            if (lastNameA < lastNameB) return -1;
            if (lastNameA > lastNameB) return 1;
                return 0;
            });
            setData(raceData);

      }

    const [raceData, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    let [activerunner, setActiveRunner] = useState(null)

    const handleFilterChange = (e) => {
        setFilterText(e.target.value.toLowerCase());
      };

    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {

            function focusField() {
                document.getElementById("raceNumber").focus()
            }

            function message() {
                if (document.getElementsByClassName("editArea")[0]) {
                window.scrollTo({ behavior: 'smooth', top: document.getElementsByClassName("editArea")[0].offsetParent.offsetTop + 370})
                setTimeout(focusField,600);
            }}

           
            setTimeout(message,100);

     
        
           

      }, [activerunner]);

    function openModal(key) {
        
        setIsOpen(true);
    }

    function moveToSearch() {
        window.scrollTo({ behavior: 'smooth', top: 250})
    }

    function afterOpenModal() {
          // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }
  
    function closeModal() {
        setIsOpen(false);
    }
    const thisData = {runnerId: 'New'}
      
    return (
        <>
           <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Participants"
            >
            
            <h2 className="modalTitle">Add a new runner</h2> 
            <EditingRow key={"new"} rowData={thisData} passChildData={closeModal} parentFunction={FetchData}/>
        </Modal>
        <div className="regArea">
            <HeaderBlock header="Night Nobbler | Registration" logo={logo} />
        </div>
        {user ?

            

        <div className="tenBlock"> 
        <h2 className="registered"> Registered: <span className="regNumbers">{registeredPeople}</span></h2>
                            <button onClick={openModal} className="newRunner">New Runner</button>
            <div className="searchHolder">

                <input type="text" placeholder="Filter by surname" onFocus={moveToSearch} value={filterText} onChange={handleFilterChange} className="filterField" /> <button className="emptyField" onClick={ () => {setFilterText('')}}>X</button>
             </div>
            <table id="runners">
                <tbody className="adminRunners">
                    <tr className="headerTable">
                        <th>First name</th>
                        <th>Last name</th>
                        {/* <th width="15px" className="alignCenter">Race</th> */}
                        <th width="15px" className="alignCenter">Cat</th>
                        {/* <th width="15px" className="alignCenter">Bib</th> */}
                        <th width="15px" className="alignCenter">Band</th>
                    </tr> 
                    {raceData
                    .filter(item => item.lastName.toLowerCase().includes(filterText))
                        .map((key, index)=> (
                    key.runnerId === activerunner ? (  
                        <EditingRow key={"editRow"+key.runnerId} rowData={key} passChildData={setActiveRunner} parentFunction={FetchData}/>             
                    ) : 
                        <TableRow key={"tableRow"+key.runnerId} rowData={key} onRowClick={ (info) => {setActiveRunner(info)}  } id={key.runnerId}/>
                ))}
            </tbody>
            </table>
            <Link className="loginLink" to="/import">Import</Link>
            <Link className="loginLink" to="/nostart">MIA - No Start</Link>
            <Link className="loginLink" to="/nofinish">MIA - No Finish</Link>
        </div>
         : <Link className="loginLink" to="/login">Login</Link>
        }
      
        </>
    );
}



export default ResultsTable;
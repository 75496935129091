import React, { useState, useEffect } from "react";
import axios from 'axios'
import moment from 'moment';
import HeaderBlock from './header'
import logo from '../assets/nn23.png'
import winnerTrophy from '../assets/trophy.png'




  function formatDuration(seconds) {
    if (seconds !== "DNF") {
    // Create a duration object with the total seconds
    const duration = moment.duration(seconds, 'seconds');
  
    // Build up the time string conditionally
    let timeString = '';
    if (duration.hours() > 0) {
      // If hours are more than 0, include them in the string
      timeString += duration.hours() + ':';
    }
    
    // Add minutes and seconds to the string
    // Use 'mm:ss' format to ensure two digits for minutes and seconds
    timeString += moment.utc(duration.asMilliseconds()).format('mm:ss');
    
    return timeString;
    } else {
        return "DNF"
    }   
  }



const ResultsTable = () => {
    const winArray = []
    const winArrayClass = []
    const [raceData, setData] = useState([]);
    const [startTime, startData] = useState([]); 



    // function openModal() {
    //   setIsOpen(true);
    // }
  
    // function afterOpenModal() {
    //   // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
    // }
  
    // function closeModal() {
    //   setIsOpen(false);
    // }

    useEffect(() => {
        fetchData(); 
        const interval = setInterval(() => {
            fetchData(); 
          }, 10000); 

          return () => clearInterval(interval); 
      }, []);

    const isWinningImage = (passedWinner, passedTime) => {
        if (winArray.includes(passedWinner) || passedTime === "DNF"){
        } else {
            winArray.push(passedWinner)
            return <img src={winnerTrophy} alt="Winner" height="10px"/>;
        }
    }


    const isWinningClass = (passedWinner, passedTime) => {
        if (winArrayClass.includes(passedWinner) || passedTime === "DNF"){
            return "norm"
        } else {
            winArrayClass.push(passedWinner)
            return 'winner'
        }
    }



    const isDNF = (passedTime) => {
        if (passedTime === "DNF"){
            return "dnf"
        } else {
            return 'finisher'
        }
    }




    const fetchData = async () => {
        try {
            const response = await axios.get('/api')
            const data = response.data.runners
            const raceData = [...data].sort((a, b) => a.runningTime - b.runningTime);
            setData(raceData);
            startData(20241207160000);

            
        } catch (err) {
            console.log(err.message);
        }
      };



    
    return (
        <div className="user">
        <HeaderBlock header="The Night Nobbler 2024" logo={logo} raceTime={startTime}  />
        <div className="tenBlock">
        <div className="tabHolder">
            {/* <div onClick={() => handleButtonClick('PB')} className={`tenkTab ${categoryType === 'PB' ? 'active' : ''}`}>Half Marathon</div> */}
            {/* <div onClick={() => handleButtonClick('BB')} className={`tenkTab ${categoryType === 'BB' ? 'active' : ''}`}>10k</div> */}
            {/* <div onClick={() => handleButtonClick('WOD')} className={`halfTab ${categoryType === 'WOD' ? 'active' : ''}`}>Big 3 Series</div> */}
        </div>
        <table id="runners">
            <tbody>
            <tr className="headerTable">
                    <th width="15px" className="alignCenter">#</th>
                    <th className="runnerCol">Runner</th>
                    <th>Club</th>
                    <th width="20px" className="alignCenter">Age</th>
                    <th width="20px" className="alignCenter">Bib</th>
                    <th width="20px" className="alignCenter">Time</th>
                </tr> 

                {raceData.map((key, index)=> (
                    <tr key={index+1} className={isDNF(key.runningTime)}>
                        <td>{index+1}</td>
                        <td>{key.firstName + " "}{key.lastName+ " "}  { isWinningImage(key.ageGroup, key.runningTime) }</td>
                        <td>{key.runClub}</td>
                        <td className={"alignCenter " + isWinningClass(key.ageGroup, key.runningTime)}>{ key.ageGroup } </td>
                        <td className="alignCenter">{key.raceNumber}</td>
                        <td className="alignCenter">{formatDuration(key.runningTime)}</td>
                    </tr>
                ))}

        </tbody>

        </table>
        </div>
       <div className="footerHolder">
                <div className="footerText">
                    Race timing by <a href="https://www.realtimeracing.co.uk/" target="_blank" rel="noreferrer" className="footer">Realtime Racing</a>
                </div> 
        </div>
        </div>
    );
}

export default ResultsTable;